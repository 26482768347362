import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Navbar, Sidebar, Footer } from './components'
import { Home } from './pages'

function App() {
  return (
    <Router>
      <Navbar />
      <Sidebar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        {/* <Route path="about" element={<About />} />
        <Route path="*" element={<Error />} /> */}
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
