import React, { useEffect } from 'react'
import styled from 'styled-components'
import blob from '../assets/blobanimation2.svg'
import meHead from '../assets/me-head-habbo-remove.png'
import { Link } from 'react-scroll'

const Connect = () => {
  return (
    <Wrapper className="section-center" id="connect">
      <div>
        <div className="blob-container">
          <img src={blob} alt="blob" className="blob-img" />
          <div className="text-overlay">
            <h5>contact me</h5>
            <p className="talk text-blue-zodiac-500">
              Got an idea? <br />
              Let's do it!
            </p>
            <a href="mailto:nic.cardozo@gmail.com?Subject=Hey%20Nico%2C%20i%27ve%20an%20idea%21">
              <p className="mail">nic.cardozo@gmail.com</p>
            </a>
            <Link to="nav" smooth={true} spy={true} duration={1500}>
              <img className="head" src={meHead} alt="my habbo head" />
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.section`
  height: calc(100vh - 7rem);
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;

  h5 {
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
  }

  .talk {
    letter-spacing: -1px;
    font-size: 3rem;
    max-width: 45rem;
    text-transform: uppercase;
    line-height: 1.1;
    font-weight: 800;
    margin-bottom: 2rem;
  }

  .mail {
    color: #fff;
    font-size: 1.8rem;
    letter-spacing: 2px;

    &:hover {
      text-decoration: underline;
    }
  }

  .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
  }

  .head {
    margin: 2rem auto 0;
    height: 120px;
  }

  // BLOB //
  .blob-container {
    position: relative;
  }

  .blob-img {
    height: 55rem;
  }

  .flip {
    display: flex;
    justify-content: center;
    transform: rotate(180deg);
  }

  .blob-motion {
    position: absolute;
    transform: translateY(-20%);
  }
  /////////

  @media (min-width: 992px) {
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text-container {
      text-align: center;
    }

    p {
      margin-bottom: 0;
    }
  }

  @media (max-width: 600px) {
    .text-overlay {
      h5 {
        color: #130f49;
      }

      .mail {
        color: #130f49;
      }
    }

    .head {
      margin: 0 auto;
      height: 120px;
    }
  }

  @media (max-width: 400px) {
    .blob-img {
      height: 440px;
    }
  }
`

export default Connect
