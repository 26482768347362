import React from 'react'
import styled from 'styled-components'
const Footer = () => {
  return (
    <Container>
      <h5 className="text-sunset-orange-500/50">
        &copy;{new Date().getFullYear()}
        <span> Nicolò Cardozo -</span>
      </h5>
      <h5 className="text-sunset-orange-500/50">&nbsp;All rights reserved</h5>
    </Container>
  )
}

const Container = styled.footer`
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 1rem;
  position: relative;

  h5 {
    letter-spacing: 1px;
    margin: 0.1rem;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1.25;
  }

  @media (max-width: 776px) {
    padding-bottom: 2rem;
  }

  @media (max-width: 414px) {
    padding-top: 2rem;
    padding-bottom: 0rem;
  }
`

export default Footer
