import React from 'react'
import logo from '../assets/me-head-habbo-remove.png'
import { Link } from 'react-scroll'
import { useSidebarContext } from '../context/sidebar_context'
import { FaTimes } from 'react-icons/fa'
import styled from 'styled-components'

const Sidebar = () => {
  const { isSidebarOpen, closeSidebar } = useSidebarContext()

  return (
    <SidebarContainer>
      <aside className={`${isSidebarOpen ? 'sidebar show-sidebar' : 'sidebar'}`}>
        <div className="sidebar-header">
          <img src={logo} className="logo" alt="my habbo head" />
          <button className="close-btn" onClick={closeSidebar}>
            <FaTimes />
          </button>
        </div>
        <ul className="links">
          <li>
            <Link
              to="about"
              smooth={true}
              spy={true}
              duration={1500}
              className="about text-blue-zodiac-500 hover:text-lblue-500"
              onClick={closeSidebar}
            >
              about
            </Link>
          </li>
          <li>
            <Link
              to="work"
              smooth={true}
              spy={true}
              duration={1500}
              className="work text-blue-zodiac-500 hover:text-lblue-500"
              onClick={closeSidebar}
            >
              work
            </Link>
          </li>
          <li>
            <Link
              to="projects"
              smooth={true}
              spy={true}
              duration={1500}
              className="projects text-blue-zodiac-500 hover:text-lblue-500"
              onClick={closeSidebar}
            >
              projects
            </Link>
          </li>
          <li>
            <Link
              to="connect"
              smooth={true}
              spy={true}
              duration={1500}
              className="connect text-blue-zodiac-500 hover:text-lblue-500"
              onClick={closeSidebar}
            >
              connect
            </Link>
          </li>
        </ul>
      </aside>
    </SidebarContainer>
  )
}

const SidebarContainer = styled.div`
  text-align: center;

  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
  }

  .close-btn {
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: var(--clr-primary-5);
    transition: var(--transition);
    cursor: pointer;
    color: #ff5156;
    margin-top: -0.2rem;
    margin-right: 1.15rem;
  }

  .close-btn:hover {
    color: #ff1920;
  }

  .logo {
    justify-self: center;
    width: 80px;
    margin-left: -15px;
  }

  .links {
    margin-bottom: 2rem;
  }

  .links a {
    display: block;
    text-align: left;
    font-size: 1rem;
    text-transform: capitalize;
    padding: 1rem 1.5rem;
    color: #130f49;
    transition: var(--transition);
    letter-spacing: var(--spacing);
    font-weight: 500;
  }

  .links a:hover {
    padding: 1rem 1.5rem;
    padding-left: 2rem;
    background: #75bfdd;
    color: #130f49;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: var(--transition);
    transform: translate(-100%);
    z-index: -1;
  }

  .show-sidebar {
    transform: translate(0);
    z-index: 999;
  }
  .cart-btn-wrapper {
    margin: 2rem auto;
  }

  @media screen and (min-width: 992px) {
    .sidebar {
      display: none;
    }
  }
`

export default Sidebar
