import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import me from '../assets/me-habbo.png'
import AOS from 'aos'
import 'aos/dist/aos.css'

const About = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Wrapper className="section-center" id="about">
      <div className="title" data-aos="zoom-out-right" data-aos-duration="1500">
        <h2 className="title-text text-blue-zodiac-500">About Me</h2>
        <p className="subtitle text-blue-zodiac-500/70">
          Insights into my background, skills, and expertise in programming and technology. Discover what I do and the
          technologies I work with. Get to know me better!
        </p>
      </div>
      <article className="content" data-aos="zoom-out-right" data-aos-duration="1500">
        <h3 className="introduce text-blue-zodiac-500">
          <span className="line">
            s<span className="f-o">o</span>
            <span className="s-o">o</span>
            <span className="t-o">o</span>
            <span className="dots">...</span>
          </span>
        </h3>

        <p className="about-me-p text-blue-zodiac-500/70">
          I currently work as a Front-End Developer at Luna Labs, where I work on technologies such as React.js,
          TypeScript, and JavaScript. In the past I worked as Salesforce Developer at Capgemini. I love building highly
          functional websites and web applications, my current goal is to become a proficient MERN stack developer, to
          build robust and scalable applications with MongoDB, Express, React.js, and Node.js. I'm dedicated to
          continuous learning and I embrace challenges with enthusiasm and remain committed to delivering the best
          results.
        </p>
      </article>
      <div className="skills" data-aos="zoom-out-left" data-aos-duration="1500">
        <h3 className="introduce my-skills text-blue-zodiac-500 ">
          <span className="line2">my skills</span>
        </h3>
        <div className="each-skill">
          <div className="skill bg-sunset-orange-300">JavaScript</div>
          <div className="skill bg-sunset-orange-300">TypeScript</div>
          <div className="skill bg-sunset-orange-300">React.js</div>
          <div className="skill bg-sunset-orange-300">Python Basics</div>
          <div className="skill bg-sunset-orange-300">Java</div>
          <div className="skill bg-sunset-orange-300">SQL</div>
          <div className="skill bg-sunset-orange-300">HTML</div>
          <div className="skill bg-sunset-orange-300">CSS</div>
          <div className="skill bg-sunset-orange-300">SASS</div>
          <div className="skill bg-sunset-orange-300">Styled Components</div>
          <div className="skill bg-sunset-orange-300">Git</div>
          <div className="skill bg-lblue-200">Apex</div>
          <div className="skill bg-lblue-200">SOQL</div>
          <div className="skill bg-lblue-200">SOSL</div>
          <div className="skill bg-lblue-200">LWC</div>
        </div>
      </div>
    </Wrapper>
  )
}

export default About

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 13rem 6rem;
  place-items: center;

  .title {
    grid-column: 1/3;
    text-align: center;
    height: 2rem;
    margin-top: 2rem;

    h2 {
      margin-bottom: 3rem;
    }

    .subtitle {
      margin: -0.5rem auto;
      line-height: 1.5;
      max-width: 45rem;
      margin-bottom: 2rem;
      letter-spacing: 0.25px;
    }
  }

  .title-text {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: 800;
    letter-spacing: -1px;
    text-transform: uppercase;
  }

  .title::after {
    content: '';
    position: absolute;
    top: 180.5%;
    height: 5px;
    width: 5rem;
    background: #34a1cd;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }

  h3 {
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;

    .f-o {
      font-size: 1.5rem;
    }

    .s-o {
      font-size: 1.75rem;
    }

    .t-o {
      font-size: 2rem;
    }

    .dots {
      font-size: 2rem;
    }
  }

  .introduce {
    margin-bottom: 1.75rem;
    margin-top: -2rem;

    .line {
      display: inline-block;
      background-image: linear-gradient(#b7deed, #b7deed);
      background-size: 100% 10px;
      background-repeat: no-repeat;
      background-position: 0% 100%;
      transition: background-size 0.5s, background-position 0.5s ease-in-out;

      &:hover {
        background-size: 100% 100%;
        background-position: 100% 0%;
        transition: background-position 0.7s, background-size 0.5s ease-in-out;
      }
    }

    .line2 {
      display: inline-block;
      background-image: linear-gradient(#ffcbcd, #ffcbcd);
      background-size: 100% 10px;
      background-repeat: no-repeat;
      background-position: 100% 0%;
      transition: background-size 0.5s, background-position 0.5s ease-in-out;
      font-size: 1.65rem;
      margin-bottom: 0.65rem;

      &:hover {
        background-size: 100% 100%;
        background-position: 0% 100%;
        transition: background-position 0.7s, background-size 0.5s ease-in-out;
      }
    }
  }

  .each-skill {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.05rem;
    justify-content: left;
  }

  .skill {
    padding: 0.75rem 1.75rem;
    margin-bottom: 1rem;
    margin-right: 1.25rem;
    font-size: 0.9rem;
    border-radius: 5px;
    font-weight: 500;
    color: #fff;
  }

  .about-me-p {
    font-size: 1rem;
    line-height: 2;
    letter-spacing: 0.25px;
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 0;

    .content {
      grid-column: 1/3;
    }

    .title {
      text-align: center;

      h2 {
        font-size: 2.5rem;
        margin-top: 7.5rem;
        margin-bottom: 5rem;
      }

      .subtitle {
        max-width: 100%;
        margin-bottom: 2rem;
        margin-top: -1.5rem;
        font-size: 1.1rem;
      }
    }

    .title::after {
      content: '';
      position: absolute;
      top: 540.5%;
      height: 5px;
      width: 5rem;
      background: #34a1cd;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 5px;
    }

    .my-skills {
      margin-top: 2rem !important;
    }

    .introduce {
      text-align: center;
      margin-top: 19.5rem;
      margin-bottom: 2rem;

      .line,
      .line2 {
        display: inline;
        transition: none;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    .each-skill {
      justify-content: flex;
      align-items: center;
      justify-content: center;
    }

    .skill {
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
      font-size: 0.8rem;
    }

    .about-me-p {
      font-size: 0.95rem;
      line-height: 2;
      letter-spacing: 0.25px;
    }
  }

  @media (max-width: 767px) {
    .content,
    .skills {
      grid-column: 1/1;
    }

    .title {
      margin-top: -7rem;
    }

    .subtitle {
      margin-bottom: 0 !important;
    }

    .introduce {
      margin-top: 18rem;
      margin-bottom: 1.5rem;
    }
    .title::after {
      content: '';
      position: absolute;
      top: 550.5%;
      height: 5px;
      width: 5rem;
      background: #34a1cd;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 5px;
    }

    .each-skill {
      justify-content: center;
    }
  }

  @media (max-width: 380px) {
    .introduce {
      margin-top: 20rem;
    }
  }
`
