import React, { useEffect } from 'react'
import styled from 'styled-components'
import Card from 'react-bootstrap/Card'
import AOS from 'aos'
import 'aos/dist/aos.css'
import llLogo2 from '../assets/llLogo2.png'
import capLogo from '../assets/capLogo.png'
import flLogo from '../assets/footLogo.svg'
import mcdoLogo from '../assets/mcLogo.png'
import adiLogo from '../assets/adiLogo.png'
import { FiExternalLink } from 'react-icons/fi'

const Work = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Wrapper className="section-center" id="work">
      <div className="title" data-aos="fade-right" data-aos-duration="1500">
        <h2 className="title-text text-blue-zodiac-500">Work Experience</h2>
      </div>
      <div className="grid">
        <div className="group">
          <a href="https://www.lunalabs.it/" target="_blank" rel="noreferrer">
            <Card
              // className="item hover:shadow-xl hover:shadow-[#fbb03c]/40 rounded-lg transition-shadow duration-1500"
              className="item rounded-md transition motion-reduce:transition-none md:group-hover:bg-[#FEF1DC] lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <Card.Body>
                <Card.Title>Luna Labs</Card.Title>
                <Card.Text className="date">03.2023 - Present</Card.Text>
                <Card.Text className="mansion">Front-End Developer</Card.Text>
              </Card.Body>
              <Card.Img src={llLogo2} />
            </Card>
          </a>
        </div>
        <div className="group">
          <a href="https://www.capgemini.com/it-it/" target="_blank" rel="noreferrer">
            <Card
              // className="item hover:shadow-xl hover:shadow-[#12abdb]/40 rounded-lg transition-shadow duration-500"
              className="item rounded-md transition motion-reduce:transition-none md:group-hover:bg-[#9AE0F7]/30 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <Card.Body>
                <Card.Title>Capgemini</Card.Title>
                <Card.Text className="date">08.2021 - 03.2023</Card.Text>
                <Card.Text className="mansion">Salesforce Developer</Card.Text>
              </Card.Body>
              <Card.Img src={capLogo} />
            </Card>
          </a>
        </div>
        <div className="group">
          <a href="https://www.adidas.it/" target="_blank" rel="noreferrer">
            <Card
              // className="item hover:shadow-xl hover:shadow-[#000]/40 rounded-lg transition-shadow duration-500"
              className="item rounded-md transition motion-reduce:transition-none md:group-hover:bg-[#000]/10 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <Card.Body>
                <Card.Title>Adidas</Card.Title>
                <Card.Text className="date">07.2018 - 07.2019</Card.Text>
                <Card.Text className="mansion">Sales Assistant</Card.Text>
              </Card.Body>
              <Card.Img className="adi-logo" src={adiLogo} />
            </Card>
          </a>
        </div>
        <div className="group">
          <a href="https://www.footlocker.it/it" target="_blank" rel="noreferrer">
            <Card
              //className="item hover:shadow-xl hover:shadow-[#Ff0004]/40 rounded-lg transition-shadow duration-500"
              className="item rounded-md transition motion-reduce:transition-none md:group-hover:bg-[#Ff0004]/10 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <Card.Body>
                <Card.Title>Foot Locker</Card.Title>
                <Card.Text className="date">10.2017 - 07.2018</Card.Text>
                <Card.Text className="mansion">Sales Assistant</Card.Text>
              </Card.Body>
              <Card.Img className="fl-logo" src={flLogo} />
            </Card>
          </a>
        </div>
        <div className="group">
          <a href="https://www.mcdonalds.it/" target="_blank" rel="noreferrer">
            <Card
              // className="item hover:shadow-xl hover:shadow-[#ffcc00]/40 rounded-lg transition-shadow duration-500"
              className="item rounded-md md:group-hover:bg-[#ffcc00]/20 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <Card.Body>
                <Card.Title>McDonald's</Card.Title>
                <Card.Text className="date">09.2013 - 09.2017</Card.Text>
                <Card.Text className="mansion">Crew</Card.Text>
              </Card.Body>
              <Card.Img className="mc-logo" src={mcdoLogo} />
            </Card>
          </a>
        </div>

        <div className="group">
          <Card className="full-resume item" data-aos="fade-left" data-aos-duration="1500">
            <Card.Body>
              <a
                href="https://drive.google.com/file/d/1bjNcp04aOe5WovXVxcSAoBpcGQZKUe8m/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                <Card.Title className="highlight resume">
                  Full Resume
                  <FiExternalLink />
                </Card.Title>
              </a>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Wrapper>
  )
}

export default Work

const Wrapper = styled.section`
  height: calc(100vh - 300px);
  position: relative;

  .highlight {
    background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=130f49);
    margin: 0 auto;
    padding: 5px 20px;
  }

  .resume {
    color: #fff !important;
    text-align: center;
  }

  .section-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .group {
    z-index: 1;
  }

  .title {
    grid-column: 1/3;
    text-align: center;
    height: 2rem;
  }

  .title-text {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: 800;
    letter-spacing: -1px;
    text-transform: uppercase;
    padding-top: 8rem;
  }

  .title::after {
    content: '';
    position: absolute;
    top: 580.5%;
    height: 5px;
    width: 5rem;
    background: #34a1cd;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    margin-top: 13rem;
    z-index: 1;
    place-items: center;
    place-items: center;
    justify-content: center;
  }

  .card-img {
    width: 65px;
    height: 65px;
    margin-left: 2rem;
  }

  .fl-logo {
    width: 90px;
  }

  .mc-logo {
    width: 60px;
    height: 60px;
    margin-left: 2.8rem;
  }

  .adi-logo {
    margin-left: 4rem;
  }

  .item {
    display: flex;
    padding: 1rem;
    flex-direction: row;
    align-items: center;
    z-index: 1;

    .card-title {
      font-size: 1.75rem;
      color: #130f49;
      letter-spacing: -1px;
      font-weight: 500;
      display: flex;
      align-items: center;

      svg {
        margin-left: 1rem;
      }
    }

    .date {
      font-size: 0.85rem;
      margin-bottom: 0.65rem;
      color: #34a1cd;
      font-weight: 500;
    }

    .mansion {
      color: #130f49;
      font-weight: 500;
    }
  }

  @media (max-width: 1096px) {
    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 4rem;
      margin-top: 12.5rem;
      justify-items: center;
    }
  }

  @media (max-width: 749px) {
    margin-bottom: 50rem;

    .grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 4rem;
      margin-top: 12.5rem;
    }

    .title::after {
      content: '';
      position: absolute;
      top: 590.5%;
      height: 5px;
      width: 5rem;
      background: #34a1cd;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 5px;
    }
  }

  @media (min-width: 749px) and (max-width: 1500px) {
    margin-bottom: 5rem;
  }

  @media (max-width: 500px) {
    margin-bottom: 65rem;

    .title-text {
      padding-top: 4rem;
    }

    .title::after {
      content: '';
      position: absolute;
      top: 400.5%;
      height: 5px;
      width: 5rem;
      background: #34a1cd;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 5px;
    }

    .grid {
      margin-top: 12.5rem;
    }
  }

  @media (max-width: 420px) {
    margin-bottom: 75rem;

    .title::after {
      content: '';
      position: absolute;
      top: 580.5%;
      height: 5px;
      width: 5rem;
      background: #34a1cd;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 5px;
    }
  }
`
