import React, { useEffect } from 'react'
import styled from 'styled-components'
import me from '../assets/me-habbo.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-scroll'

const Hero = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Wrapper className="section-center page" id="hero">
      <article className="content" data-aos="zoom-out-right" data-aos-once="true" data-aos-duration="1500">
        <h1 className="text-blue-zodiac-500">
          Aspiring MERN <br />
          <span className="text-lblue-500">Stack Developer </span> <br />
          with experience <br />
          <span className="text-lblue-500">as Salesforce</span>
          <br />
          Developer
        </h1>
        <p className="text-blue-zodiac-500/50">
          I can help you building efficient web solutions or i'll leverage the power of Salesforce to enhance your
          business processes and drive growth.
        </p>
        <div className="btn-container flex gap-4 text-white mt-10 aos-init aos-animate">
          <Link
            to="work"
            smooth={true}
            spy={true}
            duration={1500}
            className="btn hero-btn text-white bg-blue-zodiac-500 rounded-xl shadow-xl hover:shadow-blue-zodiac-500/40 transition linear delay-50 hover:-translate-y-0.5 duration-200"
          >
            work experience
          </Link>
          <Link
            to="projects"
            smooth={true}
            spy={true}
            duration={1500}
            offset={390}
            className="btn hero-btn text-white bg-lblue-500 rounded-xl shadow-xl hover:shadow-lblue-500/50 transition linear delay-50 hover:-translate-y-0.5 duration-200"
          >
            my projects
          </Link>
        </div>
      </article>
      <div className="img-container" data-aos="zoom-out-left" data-aos-once="true" data-aos-duration="1500">
        <img src={me} alt="me as habbo" />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: grid;
  place-items: center;

  h1 {
    margin-top: -1rem;
    margin-bottom: 1.5rem;
    font-size: 2.75rem;
    font-weight: 800;
    letter-spacing: 1px;
    text-align: center;
  }

  p {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .btn-container {
    justify-content: center;
    align-items: center;
  }

  .hero-btn {
    font-size: 0.75rem;
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
    padding: 0.95rem 1.5rem;
    text-transform: uppercase;
  }

  .img-container {
    display: none;
    margin-top: -5rem;
  }

  @media (min-width: 992px) {
    min-height: calc(100vh - 6.5rem);
    grid-template-columns: 1fr 1fr;
    gap: 5rem;

    h1 {
      margin-bottom: 2rem;
      font-size: 4.2rem;
      font-weight: 800;
      letter-spacing: 1px;
    }

    p {
      font-size: 1rem;
    }

    .hero-btn {
      padding: 0.95rem 1.5rem;
      font-size: 0.95rem;
      font-weight: 500;
      width: 12rem;
      text-align: center;
      text-transform: uppercase;
    }

    .img-container {
      display: block;
      position: relative;
    }

    .img-container {
      width: 25rem;
      height: 25rem;
      border-radius: 50%;
      background-image: radial-gradient(#ffcbcd 20%, transparent 15%);
      background-color: var(--clr-white);
      background-position: 0 0;
      background-size: 40px 40px;

      img {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
`

export default Hero
