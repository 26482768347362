import React from 'react'
import { Hero, About, Work, Projects, Connect } from '../components'

const HomePage = () => {
  return (
    <main>
      <Hero />
      <About />
      <Work />
      <Projects />
      <Connect />
    </main>
  )
}

export default HomePage
