import React, { useEffect } from 'react'
import styled from 'styled-components'
import Card from 'react-bootstrap/Card'
import AOS from 'aos'
import 'aos/dist/aos.css'
import dojo from '../assets/thedojo.png'
import mbc from '../assets/mbc.png'
import mock from '../assets/mockwebsite2.png'
import { BsArrowLeft } from 'react-icons/bs'
import layer1 from '../assets/layer1.svg'

const Projects = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <Wrapper id="projects">
      <div className="spacer layer1"></div>
      <div>
        <div className="section-c">
          <div data-aos="fade-right" data-aos-duration="1500">
            <div className="title">
              <p className="high-t text-sunset-orange-500">portfolio</p>
              <h2 className="title-text text-white">projects</h2>
            </div>
            <div className="grid">
              <div className="group">
                <Card
                  className="item rounded-md transition motion-reduce:transition-none md:group-hover:bg-sunset-orange-50/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <Card.Img
                    src={mbc}
                    className="border-2 border-sunset-orange-400/10 transition group-hover:border-sunset-orange-500/30"
                  />
                  <Card.Body>
                    <Card.Title>Millionaire Boys Club</Card.Title>
                    <Card.Text className="descr">Second hand luxury watches shop</Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="group">
                <Card
                  className="item rounded-md transition motion-reduce:transition-none md:group-hover:bg-sunset-orange-50/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <Card.Img
                    src={dojo}
                    className="border-2 border-sunset-orange-400/10 transition group-hover:border-sunset-orange-500/30"
                  />
                  <Card.Body>
                    <Card.Title>The Dojo Project</Card.Title>
                    <Card.Text className="descr">American Akita and Samoyed dog breeding</Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="group">
                <a href="mailto:nic.cardozo@gmail.com?Subject=Hey%20Nico%2C%20i%27ve%20an%20idea%21">
                  <Card
                    className="item rounded-md transition motion-reduce:transition-none md:group-hover:bg-sunset-orange-50/50 lg:group-hover:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)] lg:group-hover:drop-shadow-lg"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <Card.Img
                      src={mock}
                      className="border-2 border-sunset-orange-400/10 transition group-hover:border-sunset-orange-500/30"
                    />

                    <Card.Body>
                      <Card.Title>
                        &nbsp;
                        <BsArrowLeft className="bounce" size={24} />
                        &nbsp;Your Website?
                      </Card.Title>
                      <Card.Text className="descr">Click on the pic to get in contact with me! </Card.Text>
                    </Card.Body>
                  </Card>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-fade"></div>
    </Wrapper>
  )
}

export default Projects

const Wrapper = styled.section`
  padding-bottom: 3rem;
  background-color: #ffcbcd;
  position: relative;
  z-index: 0;

  /////////////////////

  .spacer {
    aspect-ratio: 960/300;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: -15rem;
  }

  .layer1 {
    position: relative;
    background-image: url(${layer1});
  }

  .w-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    z-index: -1;
    background: linear-gradient(transparent, #fff 100%);
  }

  ///////////////////

  /// ICON BOUNCE ///

  .bounce {
    display: inline-block;
    position: relative;
    -moz-animation: bounce 0.5s infinite linear;
    -o-animation: bounce 0.5s infinite linear;
    -webkit-animation: bounce 0.5s infinite linear;
    animation: bounce 0.6s infinite linear;
  }
  .fa-twitter {
    color: #027bdd;
    font-size: 3em;
    margin: 20px;
  }

  @-webkit-keyframes bounce {
    0% {
      top: 0;
    }
    50% {
      top: -0.2em;
    }
    70% {
      top: -0.3em;
    }
    100% {
      top: 0;
    }
  }

  @-moz-keyframes bounce {
    0% {
      left: 0;
    }
    50% {
      left: -0.2em;
    }
    70% {
      left: -0.3em;
    }
    100% {
      left: 0;
    }
  }

  @-o-keyframes bounce {
    0% {
      left: 0;
    }
    50% {
      left: -0.2em;
    }
    70% {
      left: -0.3em;
    }
    100% {
      left: 0;
    }
  }
  @-ms-keyframes bounce {
    0% {
      left: 0;
    }
    50% {
      left: -0.2em;
    }
    70% {
      left: -0.3em;
    }
    100% {
      left: 0;
    }
  }
  @keyframes bounce {
    0% {
      left: 0;
    }
    50% {
      left: -0.2em;
    }
    70% {
      left: -0.3em;
    }
    100% {
      left: 0;
    }
  }

  /// ICON BOUNCE ///

  .white-fade {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(255, 255, 255, 0) linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%)
      repeat scroll 0 0;
    padding-bottom: 5rem;
  }

  .high-t {
    text-transform: uppercase;
    text-align: left;
    margin: -0.5rem;
    font-size: 0.85rem;
    margin-left: 0.15rem;
    font-weight: 600;
  }

  .section-c {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
    margin-top: 1rem;
  }

  .title-text {
    height: auto;
    font-size: 3rem;
    font-weight: 800;
    letter-spacing: -1px;
    text-transform: uppercase;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    place-content: center;
    place-items: center;
    left: 0;
    right: 0;
  }

  .group {
    position: relative;
    z-index: 2;
  }

  .card-img {
    width: 150px;
    height: 100px;
    margin-right: 2rem;
    border-radius: 5px;
  }

  .item {
    display: flex;
    padding: 1.5rem;
    flex-direction: row;
    align-items: center;
    width: 33rem;
    z-index: 3;

    .card-title {
      font-size: 1.75rem;
      color: #ff5156;
      letter-spacing: -1px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }

    .descr {
      margin-bottom: 0.65rem;
      color: #ffa3a5;
      font-weight: 500;
      font-size: 0.9rem;
    }
  }

  @media (max-width: 1096px) {
    .spacer {
      margin-top: 0; /* Remove negative margin for mobile and tablet */
    }

    .high-t {
      margin-top: -1rem; /* Adjust spacing for mobile and tablet */
      text-align: center;
    }

    .title-text {
      text-align: center;
    }

    .section-c {
      padding-top: 2rem; /* Add some padding for spacing on mobile and tablet */
    }
  }

  @media (max-width: 920px) {
    padding: 2rem 0;

    .white-fade {
      padding-bottom: 10rem;
    }

    .grid {
      grid-template-columns: repeat(1, 1fr); /* Display cards in a single column for medium screens */
    }

    .item {
      width: 100%; /* Set cards' width to 100% for medium screens */
    }
  }

  @media (max-width: 650px) {
    background: #fff4f5;

    .section-c {
      padding: 0;
    }

    .layer1 {
      display: none;
    }

    .white-fade {
      background: #fff4f5;
      padding-top: 10rem;
    }

    .title-text {
      color: #ff5156;
    }

    .grid {
      grid-template-columns: repeat(1, 1fr); /* Display cards in a single column for small screens */
    }

    .item {
      padding: 1rem 0; /* Reduce padding for small screens */
    }

    .card-title {
      font-size: 1.3rem !important;
    }
  }
`
