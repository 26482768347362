import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FaBars } from 'react-icons/fa'
import meHead from '../assets/me-head-habbo.jpg'
import { useSidebarContext } from '../context/sidebar_context'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-scroll'

const Nav = () => {
  const { openSidebar } = useSidebarContext()

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <NavContainer id="nav">
      <div className="nav-center" data-aos="fade-down" data-aos-once="true" data-aos-duration="1500">
        <div className="nav-header">
          <Link to="hero">
            <img src={meHead} alt="my habbo head" />
          </Link>
          <button type="button" className="nav-toggle" onClick={openSidebar}>
            <FaBars />
          </button>
        </div>
        <ul className="nav-links">
          <li>
            <Link
              to="about"
              smooth={true}
              spy={true}
              duration={1500}
              className="about text-blue-zodiac-500 hover:text-lblue-500"
            >
              about
            </Link>
          </li>
          <li>
            <Link
              to="work"
              smooth={true}
              spy={true}
              duration={1500}
              className="work text-blue-zodiac-500 hover:text-lblue-500"
            >
              work
            </Link>
          </li>
          <li>
            <Link
              to="projects"
              smooth={true}
              spy={true}
              duration={1500}
              offset={390}
              className="projects text-blue-zodiac-500 hover:text-lblue-500"
            >
              projects
            </Link>
          </li>
          <li>
            <Link
              to="connect"
              smooth={true}
              spy={true}
              duration={1500}
              className="connect text-white bg-sunset-orange-500 rounded-xl shadow-xl hover:shadow-sunset-orange-500/40 transition linear delay-50 hover:-translate-y-0.5 duration-200"
            >
              connect
            </Link>
          </li>
        </ul>
      </div>
    </NavContainer>
  )
}

const NavContainer = styled.nav`
  height: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .nav-center {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }

  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 110px;
      margin-left: -15px;
    }
  }

  .nav-toggle {
    background: transparent;
    border: transparent;
    color: var(--clr-primary-5);
    cursor: pointer;
    svg {
      font-size: 2rem;
    }
  }

  .nav-links {
    display: none;
  }

  @media (min-width: 992px) {
    .nav-toggle {
      display: none;
    }

    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }

    .nav-links {
      display: flex;
      justify-content: right;

      li {
        margin: 0 0.5rem;
      }

      a {
        font-size: 0.95rem;
        text-transform: capitalize;
        letter-spacing: 0.05rem;
        padding: 1rem;
        font-weight: 500;
      }

      .connect {
        height: 1.5rem;
        padding: 0.75rem 1.5rem;
      }
    }
  }

  @media (max-width: 503px) {
    height: 5.5rem;
  }
`

export default Nav
